

import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { Observable} from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    req = req.clone(this.jwt());
    return next.handle(req);

  }

  /**
   * @method : jwt
   * @purpose:- Set Authoriztion as Token and also set as Authoriztion..
   */
  private jwt() {

    const token = localStorage.getItem('token') ? localStorage.getItem('token') : null;
    if (token) {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: "Bearer " + token,
          'User-Id': '125'
        })
      };
    } else {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
    }
  }
}
