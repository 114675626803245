<header tabindex="-1">
    <div class="container-fluid top-header">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="logo"><img [src]="image$ | async" alt="logo"></div>
            </div>

            <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="header-right">
                    <div class="welcome-user float-left">

                        <div class="dropdown">

                        </div>
                         <div class="profile-bg">
                            <button mat-button [matMenuTriggerFor]="menu"><img [src]="profilePic$ | async" alt="profile"></button>
                            <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="redirectProfile('profile',0)"><i class="material-icons">account_box</i>Profile</button>
                            <button mat-menu-item (click)="redirectProfile('profile',1)"><i class="material-icons">settings</i>Setting</button>
                            </mat-menu>
                        </div>
                         <div class="welcome-lastseen">
                           <div class="welcome">

                             {{ (fullName$ | async) }}
                           </div>
                           <div class="last-seen">
      Last Login : <span>{{ (dateText$ | async) }}</span>
  </div>
                           
                         </div>
                    </div>
                    <div class="logout float-left">
                            <a tabindex="-1" href="javascript:void(0)" (click)="logout()"><i class="material-icons">exit_to_app</i> Logout</a>
                    </div>
                </div>
                    
            
            </div>
        </div>
    </div>

    <div class="container-fluid header-menu">
        <div class="row">
            <div class="col-lg-12">
                <ul *ngIf="this.router.url.indexOf('admin') > -1">
                    <li><a tabindex="-1" href="javascript:void(0)" [routerLink]="['/admin/dashboard']" [routerLinkActive]="'active'" >Dashboard</a></li>
                    <li><a tabindex="-1" href="javascript:void(0)"  [routerLink]="['/admin/orders']" [routerLinkActive]="'active'">Home</a></li>
                    <li><a tabindex="-1" href="javascript:void(0)"  [routerLink]="['/admin/task']" [routerLinkActive]="'active'">Task</a></li>
                    <li><a tabindex="-1" href="javascript:void(0)"  [routerLink]="['/admin/report']" [routerLinkActive]="'active'">Report</a></li>
                </ul>

                <ul *ngIf="this.router.url.indexOf('admin') == -1">
                        <li><a tabindex="-1" href="javascript:void(0)" [routerLink]="['/client/dashboard']" [routerLinkActive]="'active'" >Dashboard</a></li>
                        <li><a  tabindex="-1" href="javascript:void(0)"  [routerLink]="['/client/orders']" [routerLinkActive]="'active'">Orders</a></li>
                        <li><a tabindex="-1" href="javascript:void(0)" (click)="redirect()" [routerLinkActive]="'active'">Contact</a></li>
                    </ul>

              <div class="news-and-info">
                <ul>
                  <li matTooltip="User Guide" matTooltipPosition="left" matTooltipClass="custom-tooltip">
                    <!--<a href="javascript:void(0)" (click)="openDialog()"><img [src]="helpImage$ | async" class="help-icon"/></a>-->
                    <!--<a href="javascript:void(0)" onclick="window.open('google.com','popup','width=1200,height=640'); return false;">
        <img [src]="helpImage$ | async" class="help-icon" />
    </a>-->

                    <a tabindex="-1" href="javascript:void(0)" class="help-icon"
                       (click)="openNewTab()">
                      <img [src]="helpImage$ | async" class="help-icon" />
                    </a>


                  </li>
                  <li style="border:none;width: 31px;height: 28px;" matTooltip="Release Notes" matTooltipPosition="left" matTooltipClass="custom-tooltip">
                    <a style="width: 30px;height: 29px;" tabindex="-1" href="javascript:void(0)" class="help-icon"
                       (click)="openReleaseTab()">
                      <!--<i class="material-icons-outlined">new_releases</i>-->
                      <img style="width: 30px;height: 27px;" [src]="releaseNoteImage$ | async" class="help-icon" />
                    </a>
                  </li>


                  <li matTooltip="News" matTooltipPosition="left" matTooltipClass="custom-tooltip">
                    <a tabindex="-1" href="https://fundingshield.com/news/" class="news-icon"
                       onclick="window.open('https://fundingshield.com/news/','popup','width=1200,height=640'); return false;">
                      NEWS
                      <span class="line line-1"></span>
                      <span class="line line-2"></span>
                      <span class="line line-3"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
        </div>

    </div>
</header>
