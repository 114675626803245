import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
//import { AddNewUserComponent } from 'src/app/modules/admin/modals/add-new-user/add-new-user.component';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
  data:any;

  constructor(public router: Router,public dialogs: MatDialog ){}

  agInit(params){
    this.data = params['data'];
  }
 
 
 opennewdialog(){
   let self = this;
   //let dialogRef =  self.dialogs.open(AddNewUserComponent, {
   //     width: '620px',
   //     data: this.data
   //});

   //dialogRef.afterClosed().pipe(
   //  filter(res => res)
   //).subscribe(res => {
   //  if (res) {
   //    console.error(res);
   //  }

   //});

 }
 ngOnInit() {
   
  }

}
