import { environment } from '../../environments/environment';
import {
    HttpEvent,
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpErrorResponse
} from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { constant } from "../constants/const";
import { Injectable } from '@angular/core';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
    //API_ENDPOINT = environment.;
    API_ENDPOINT = '';
    constructor() {

    }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const apiReq = req.clone({ url: `${this.API_ENDPOINT}${req.url}` });
        return next.handle(apiReq);
       //return next.handle(req);
       
    }
}
