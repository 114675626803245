import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanLoad,
  Route,
} from "@angular/router";
import { Observable } from "rxjs";

import { MsalService } from "src/app/services/msal.service";

@Injectable()
export class ClientGuard implements CanActivate, CanLoad {
  constructor(private router: Router, public msalService: MsalService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let self = this;
    if (self.msalService.isLoggedIn()) {
      return true;
    }
    self.router.navigate(["/login"]);
    return false;
  }

  /**
   * @method: canLoad
   * @purpose check is auth or not
   */
  async canLoad(route: Route) {
    console.log("auth");
    if (await this.userLoggedIn()) {
      return true;
    }
    this.router.navigate(["/login"]);
    return false;
  }

  /**
   * @method: userLoggedIn()
   * @purpose : use have `Local storage.`
   */
  async userLoggedIn() {
    return localStorage.getItem("currentUser") ? true : false;
  }
}
