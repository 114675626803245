import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse
} from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { constant } from "../constants/const";
import { Validations } from '../constants/validations';
import { Utility } from '../async/utility';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  public isRander = false;
  constructor(private toastr: ToastrService,
    private utilApi: Utility,
    public router: Router) {

  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {


    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {

        //remove all toast exept last one
        let self = this;
        if (this.toastr.toasts.length > 0) {
          for (let i = 0; i < this.toastr.toasts.length; i++) {
            let toastId = self.toastr.toasts[i].toastId;
            if (self.toastr.toasts[i].message.indexOf('session has expired') == -1) {
              self.toastr.remove(toastId);
            }
          }
        }


        if (error.status == 0) {
          this.toastr.clear();
          this.toastr.error(Validations.Message.serverNotFound);
          //this.utilApi.setLogout();
        } else if (error.status == 402 || error.status == 401) {
          let self = this;
          if (this.router.url.indexOf('/user-type') == -1) {
            if (!this.isRander) {
              this.isRander = true;
              this.toastr.clear();
              this.toastr.error(Validations.Message.InvalidUsers);
              this.utilApi.setLogout();

              setTimeout(function () {
                self.router.navigate(["/"]);
                return throwError(error);
              }, 2000);
            }
            else {
              this.utilApi.setLogout();
              this.router.navigate(["/"]);
            }

          }
        } else if (error.status == 403) {
          if (!this.isRander) {
            this.isRander = true;
            this.toastr.clear();
            this.toastr.error(Validations.Message.accessDenied);
            this.utilApi.setLogout();
          }
          else {
            this.utilApi.setLogout();
            this.router.navigate(["/"]);
          }
        }

        return throwError(error);
      })
    );
  }
}
