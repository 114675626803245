import { Injectable, OnInit } from "@angular/core";
import { CoreService } from "./core.service";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";

import { SessionStorageService } from "src/app/services/sessionStorage.service";

@Injectable({
  providedIn: "root",
})
export class SharedService extends CoreService implements OnInit {
  public API_ENDPOINT;
  public config$: Observable<any>;

  public NODE_URL = "/currentConfigs.js";

  constructor(
    public http: HttpClient,
    private storage: SessionStorageService,
    private loader: NgxUiLoaderService,
    private toast: ToastrService
  ) {
    super(http);
    let self = this;

    if (
      window.location.host &&
      window.location.host.indexOf("localhost") > -1
    ) {
      this.NODE_URL = "assets/configs.json";
      // this.setConfiguaration();
    } else {
      this.NODE_URL = "/currentConfigs.js";
      // this.setConfiguaration();
    }

    if (localStorage.getItem("configData")) {
      let env = JSON.parse(localStorage.getItem("configData"));
      self.API_ENDPOINT = env.API_ENDPOINT;
    }
  }

  ngOnInit() {
    let self = this;
    if (localStorage.getItem("configData")) {
      let env = JSON.parse(localStorage.getItem("configData"));
      self.API_ENDPOINT = env.API_ENDPOINT;
    }
  }

  setConfigApi(): Observable<any> {
    if (
      (window.location.host &&
        window.location.host.indexOf("localhost") > -1) ||
      window.location.host.indexOf("192.168.0.30") > -1
    ) {
      let host = "localhost";
      if (window.location.host.indexOf("192.168.0.30") > -1) {
        host = "192.168.0.30";
      }
      return of({
        timestamp: "2020-02-17T07:01:07.365Z",
        API_ENDPOINT: "https://consumer-api-dev.fs-cwavs.com/",
        B2C_SCOPE: '["openid", "profile"]',
        CLIENT_KEY: "4cbb14bf-91f6-4eef-90a7-d5e27ad6bfdc",
        MICROSOFT_LOGIN_URL: "https://login.microsoftonline.com/tfp/",
        MSAL_REDIRECT_URL: `http://${host}:44308/user-type`,
        finicityMode: `Test`,
        RESET_PWD_POLICY: "B2C_1_PasswordReset",
        SIGNIN_POLICY: "B2C_1_SignUpSignIn",
        SIGNUP_POLICY: "B2C_1_SignIn",
        EDIT_POLICY: "B2C_1_EditProfile",
        TENANT_KEY: "aaf09b0a-e24c-4359-b3d4-7553a65a722d",
        AG_GRID_KEY:
          "Roosevelt_Management_Company_Camp_David_2Devs17_June_2020__MTU5MjM0ODQwMDAwMA==b9b90341e8eb23be657bef7842cfa668",
      });
    } else {
      return this.get(this.NODE_URL);
    }
  }
  setConfiguaration() {
    this.get(this.NODE_URL).subscribe(
      (value) => {
        if (value) {
          this.config$ = of(value);
          localStorage.setItem("configData", JSON.stringify(value));
          this.API_ENDPOINT = value["API_ENDPOINT"];
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  /**
   * @method: login
   * @param reqData
   */
  public login(reqData: object): Observable<any> {
    return this.post(`${this.API_ENDPOINT}/Login`, reqData);
  }

  public isLogedin(): Observable<any> {
    return localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser"))
      : undefined;
  }

  /**
   * @method: getProfile
   */
  getProfile(reqObj): Observable<any> {
    //return this.get(this.API_ENDPOINT + `User/GetUser?loggedInUserId=${reqObj['userKey']}&azureUserId=${reqObj['azureUserKey']}`)

    let baseUrl;
    if (localStorage.getItem("configData")) {
      let configData = JSON.parse(localStorage.getItem("configData"));
      baseUrl = configData["API_ENDPOINT"];
    }
    if (this.API_ENDPOINT) {
      return this.get(
        this.API_ENDPOINT +
          `User/GetUser?loggedInUserId=${reqObj["userKey"]}&azureUserId=${reqObj["azureUserKey"]}`
      );
    } else {
      return this.get(
        this.API_ENDPOINT ||
          baseUrl +
            `User/GetUser?loggedInUserId=${reqObj["userKey"]}&azureUserId=${reqObj["azureUserKey"]}`
      );
    }
  }

  /**
   * @method : getClientList
   * @required : @this method Get Client List.
   */
  getClients(reqObj) {
    return this.get(`assets/api/client-list.json`);
  }

  request(url: string, method: string, token: string, data: any = "") {
    const options = {
      method: method, // GET, POST, PUT, DELETE.
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: "",
    };
    if (data) {
      options.body = JSON.stringify(data);
    } else {
      delete options.body;
    }

    return fetch(url, options);
  }

  createToken(payload: any) {
    return this.post(this.API_ENDPOINT + "Token/Create", payload).toPromise();
  }

  getDomainURL() {
    return this.API_ENDPOINT;
  }

  getUser(email: string) {
    return this.get(
      this.API_ENDPOINT + `User/GetUsersByUserName?userName=${email}`
    ).toPromise();
  }

  getRegisterCode(email: string) {
    return this.get(
      this.API_ENDPOINT +
        `PreRegistration/CreateCWavsPreRegistration?Emailid=${email}`
    ).toPromise();
  }

  sendRegisterCodeUWAVS(Id: string, X: string, Y: string) {
    return this.get(
      this.API_ENDPOINT +
        `PreRegistration/SendCWavsPreRegistrationEmailUWAVS?Id=${Id}&x=${X}&y=${Y}`
    ).toPromise();
  }

  sendRegisterCode(orderId: string) {
    return this.get(
      this.API_ENDPOINT +
        `PreRegistration/SendCWavsPreRegistrationEmail?CWAVSOrderId=${orderId}`
    ).toPromise();
  }

  createUser(body: any, code: string) {
    return this.post(
      this.API_ENDPOINT + `User/Create?RegistrationCode=${code}`,
      body
    ).toPromise();
  }

  getCompanyDetails(userId: any) {
    return this.get(
      this.API_ENDPOINT +
        `CWavsCompany/GetCWavsCompanyByUserId?LoggedInUser=${userId}`
    ).toPromise();
  }

  getConfigColors(companyId: any) {
    return this.get(
      this.API_ENDPOINT +
        `CWavsLenderForm/GetCWavsLenderFormBasicsById?CompanyID=${companyId}`
    ).toPromise();
  }

  getOrderDetails(orderId: any) {
    return this.get(
      this.API_ENDPOINT +
        `CWAVSOrder/GetCWAVSOrderById?CWAVSOrderById=${orderId}`
    ).toPromise();
  }

  getAllBankAccountDetails(emailId: any, lendarId: any, inviteId: any, userId: any) {
    return this.get(
      this.API_ENDPOINT +
        `FinicityReport/GetAllAccountDetails?emailId=${emailId}&lendarId=${lendarId}&inviteId=${inviteId}&userId=${userId}`
    ).toPromise();
  }

  getInviteDetails(x: any, y: any) {
    return this.get(
      this.API_ENDPOINT +
        `BankVerification/GetInviteDetails?x=${x}&y=${y}`
    ).toPromise();
  }

  async getFormFields(companyId: any, token: string): Promise<any> {
    const response = await this.request(
      this.API_ENDPOINT +
        `CWavsLenderForm/GetCWavsLenderFormById?CompanyID=${companyId}`,
      "GET",
      token
    );

    return response.json();
  }

  async createOrder(body: any, token: string): Promise<any> {
    const response = await this.request(
      this.API_ENDPOINT + "CWAVSOrder/AddNewCWAVSOrder",
      "POST",
      token,
      body
    );

    return response.json();
  }

  async submitOrder(body: any, token: string): Promise<any> {
    const response = await this.request(
      this.API_ENDPOINT + "CWAVSOrder/SubmitCWAVSOrder",
      "POST",
      token,
      body
    );

    return response.json();
  }

  async submitCwavsOrder(
    stepper: any,
    optionalFields: any = {}
  ): Promise<void> {
    const token = this.storage.get("sessionToken");
    const body = {
      abaNumber: stepper.routingNumber,
      acctNumber: stepper.accountNumber,
      nameOnClosingAgentWireAccount: stepper.accountName,
    };

    let payload = { ...body, ...optionalFields };

    try {
      this.loader.start();

      // Get first the order id details
      const orderResponse: any = await this.getOrderDetails(stepper.orderId);

      // Merge the response from the order details with the payload
      payload = { ...orderResponse.value, ...payload };

      // Submit order
      const submitResponse: any = await this.submitOrder(payload, token);

      if (submitResponse.value) {
        if (stepper) {
          stepper.order = submitResponse.value;
          stepper.isSuccessfulWired =
            parseInt(submitResponse.value.messageId) === 7;
          stepper.next();
        }
      }
    } catch (error) {
      console.log(error);
      this.toast.info(`Something wrong happens`);
    } finally {
      this.loader.stop();
    }
  }
  public generateToken(finicityURL:string): Observable<any> {
    const options = {
      method: "GET", // GET, POST, PUT, DELETE.
      headers: {
        "Access-Control-Allow-Origin": "*"
      }
    };
    return this.http.get<any>(finicityURL,options);
  }
  // generateToken(finicityURL:string): Observable<any>  {
  //   return this.get(finicityURL);
  // }

  createCustomer(payload: any = "", activeToken:string, finicityURL:string, headersToPick:string): Promise<any> {
    const options = {
      method: "POST", // GET, POST, PUT, DELETE.
      headers: {
        "finicity-app-token": activeToken,
        "Access-Control-Allow-Origin": "*"
      },
      body: null,
    };
    if (payload) {
      options.body = payload;
    } else {
      delete options.body;
    }

    return fetch(this.API_ENDPOINT + 'Finicity/FinicityAPICall?FinicityURL=' + finicityURL + "&HeadersToPick=" + headersToPick, options);
  }
  generateLinkCustomer(payload: any = "", activeToken:string, finicityURL:string, headersToPick:string): Promise<any> {
    const options = {
      method: "POST", // GET, POST, PUT, DELETE.
      headers: {
        "finicity-app-token": activeToken,
        "Access-Control-Allow-Origin": "*"
      },
      body: "",
    };
    if (payload) {
      options.body = JSON.stringify(payload);
    } else {
      delete options.body;
    }

    return fetch(this.API_ENDPOINT + 'Finicity/FinicityAPICall?FinicityURL=' + finicityURL + "&HeadersToPick=" + headersToPick, options);
  }

  getFinicityUser(email: string) {
    return this.get(
      this.API_ENDPOINT + `CWavsFinicity/GetCWavsFinicityCustomer?FinicityCustomerUserName=${email}`
    ).toPromise();
  }

  insertFinicityUser(payload: any) {
    return this.post(
      this.API_ENDPOINT + `CWavsFinicity/AddNewCWavsFinicityCustomer`, payload
    ).toPromise();
  }

  async uploadFiles(files: any, orderId: string, userId: any) {
    const options = {
      method: "POST", // GET, POST, PUT, DELETE.
      body: files,
    };

    const url =
      this.API_ENDPOINT +
      `UploadOrderFile?CWavsOrderId=${orderId}&LoggedInUser=${userId}`;

    return fetch(url, options);
  }

  sendForgotPasswordEmailUWAVS(Id: number, x: string, y: string) {
    return this.get(
      this.API_ENDPOINT +
        `PreRegistration/SendPasswordResetEmailUWAVS?Id=${Id}&x=${Id}&y=${Id}`
    ).toPromise();
  }

  sendForgotPasswordEmail(orderId: any) {
    return this.get(
      this.API_ENDPOINT +
        `PreRegistration/SendPasswordResetEmail?CWAVSOrderId=${orderId}`
    ).toPromise();
  }

  resetPassword(orderId: string, code: string, password: string) {
    return this.get(
      this.API_ENDPOINT +
        `PreRegistration/ResetNewPassword?CWAVSOrderId=${orderId}&PreRegistrationcode=${code}&NewPassword=${password}`
    ).toPromise();
  }
}
