import { Component, OnInit } from '@angular/core';
//import { AddNewParentComponent } from 'src/app/modules/admin/modals/add-new-parent/add-new-parent.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-client-role-inner-grid-edit',
  templateUrl: './client-role-inner-grid-edit.component.html',
  styleUrls: ['./client-role-inner-grid-edit.component.css']
})
export class ClientRoleInnerGridEditComponent implements OnInit {
  data: any;
  isAdd: boolean;
   
  constructor(public router: Router,public dialogs: MatDialog ) { }

  ngOnInit() {
  }
  openDialog(){
  let self =this
  //self.dialogs.open(AddNewParentComponent, {
  //    width: '620px',
  //    data: {
  //      status: 'edit_child',
  //      value:{ ...this.data}
  //    }
  //  });
}
agInit(params){
  this.data = params['data'];
}

}
