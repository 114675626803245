<div class="front-page">
        <div class="table-cell">
            <mat-card class="front-mat-card">
                <mat-card-header>
                    <mat-card-title>
                        <h2>Page Not Found</h2>
                        <span class="login-logo">
                            <img src="/assets/img/logo.png" alt="logo">
                        </span>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                   <hr/>
                   <br/>
                   <h3 class="text-align" style="text-align: center;margin-bottom: 50px">404 : Page Not Found!</h3>
                   <br/>
                </mat-card-content>
            </mat-card>
        </div>
    </div>