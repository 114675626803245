import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class Utility {
    login$ = new Subject<any>();
    isLoad$ = new Subject<any>();
    profilePic$ = new Subject<any>();
    isClientFormValid$ = new Subject<any>();
    redirectProfile$ = new Subject<any>();
    tabChanged$ = new Subject<any>();
    wireInfo$ = new Subject<any>(); 
    constructor() {
    }
    /**
     * @method:  set  logout.
     */
    setLogout() {
        this.login$.next(true);
    }
    /**
     * @method: `Logout`
     * @requires: for user logout as Async based..
     */
    logout(): Observable<any> {
        return this.login$.asObservable();
    }
  /**
   *@get User Id
   * */
  getUserId() {
    if (localStorage.getItem('userKey')) {
      return localStorage.getItem('userKey');
    } else {
      return null
    }
  }

  getUserType() {
    if (localStorage.getItem('userType')) {
      return JSON.parse(localStorage.getItem('userType'));
    } else {
      return 1;
    }
  }
    /**
     * @function : isLoad
     */
    isLoad(value = false) {
        this.isLoad$.next(value)
    }

    /**
     * @loaderStatusGet
     */
    loaderStatusGet(): Observable<any> {
        return this.isLoad$.asObservable();
    }

    /**
     * @client info form valid.
     * @set as valid or not.
     */
    setClientFormValid(status) {
        this.isClientFormValid$.next(status)
    }
    /**
     * @method : getClientForm
     * get the data
     */
     getClientFormValid(status) {
        return this.isClientFormValid$.asObservable();
     }


    // * @Profile Button On `click as Header`
    /**
     * @Set
     * @Util : for set tab as click on profile but for that created it.
     * `Name` : `redirectProfile$:`
     */
     redirectSetProfile(tabNumber = 0) {
        this.redirectProfile$.next(tabNumber);
     }
    /**
     * @Get
     * @Util : for the get as set Profile method
     * `Name` : `redirectGetProfile$:`
     */
     redirectGetProfile() {
        return this.redirectProfile$.asObservable();
     }


     setTabChanged(selectIndex) {
        this.tabChanged$.next(selectIndex);
     }

     getTabChanged() {
        return this.tabChanged$.asObservable();
     }

     setWired$(obj) {
      this.wireInfo$.next(obj);
     }

   getWired$() {
      return this.wireInfo$.asObservable();
   }


  getProfile() {
    return this.profilePic$.asObservable();
  }

  setProfile(obj) {
    this.profilePic$.next(obj)
  }
}
