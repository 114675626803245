


import { Component, OnInit, OnDestroy, NgModule } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { FormService } from 'src/app/services/forms/form.service';
import { SharedService } from 'src/app/services/shared.service';
import { Helper } from 'src/app/shared/classes/helpers.class';
import { Subscription } from 'rxjs';
import { Utility } from 'src/app/async/utility';
import { ClientLabel } from '../../constants/client.label';
import { Validations } from '../../constants/validations';
import { NgxAdalService } from 'ngx-adal-8';
import { CellComp } from 'ag-grid-community';
import { ToastrService } from "ngx-toastr";
import { MsalService }  from '../../services/msal.service';
@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.css']
})
//@NgModule({
//    declarations:[LoginComponent]
//})
export class LoginComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;
    constLabel= ClientLabel;
    validMsg = Validations;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    helper: Helper = new Helper();
    loginUser: any;
    subscription: Subscription;
  passwordShow = false;
  isFirstAttempt = false;
    //private authService: NgxAdalService
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fs: FormService,
        private shareApi: SharedService,
        private utilService: Utility,
       private toast: ToastrService,
       public msalService:MsalService
    
        
    ) {
      // this.msalService.login();
      //  this.loginForm = this.fs.createLoginForm();
      this.utilService.isLoad(true);
    }


  ngOnInit() {
    setTimeout(() => {
        this.msalService.login();
    }, 5000)
  }

    /**
     * @method : `login`
     * @purpose : `login authenticated users.`
     */
    //async login() {
    //  //  this.authService.login();
    //    this.utilService.isLoad(true);
    //    const reqObj = await this.helper.formData(this.loginForm);
    //    this.subscription = this.shareApi.login(reqObj).subscribe(async value => {
    //        await this.helper.success(value) ? this.success(value, 'login') : this.errorHandler(value)
    //    }, error => this.errorHandler(error));


    //}

    /**
     * @method : `errorHandler` must be check if any error
     */
  //async errorHandler(err) {
  //    console.error(err)
  //      console.error(err)
  //  }


  //async success(res = [], type = null) {
  //      switch (type) {
  //        case 'login':
            
  //          if (res && res.hasOwnProperty("status") && res["status"] == "Failed") {
  //            this.utilService.isLoad(false);
  //            this.toast.error("The credentials you supplied were not correct or did not grant access to this resource", "User Authentication!!");
  //          } else {
  //              this.loginUser = await this.helper.response(res);
  //              this.userRedirect(this.loginUser);
  //              this.toast.success("You are login successfully.", "User Authentication!!");
  //          }
  //          break;
  //          default:
  //              break;
  //      }
  //  }

    /**
     * @method : `userRedirect`
     * @purpose : `this is checked redirect. as based on user role`
     */
    //userRedirect(loginUser): void {
    //    this.utilService.isLoad(true);
    //  if (loginUser.hasOwnProperty('userType') && loginUser.userType == 'client') {
    //        localStorage.setItem('currentUser', JSON.stringify(loginUser));
    //        this.router.navigate(['/client/orders']);
    //  } else if (loginUser.hasOwnProperty('userType') && loginUser.user_type == 'admin') {
    //        localStorage.setItem('currentUser', JSON.stringify(loginUser))
    //        this.router.navigate(['/admin/']);
    //    } else {
    //        this.utilService.isLoad(false);
    //        localStorage.clear();
    //    }
    //}

    ngOnDestroy() {
        this.loginForm = null;
        this.loginUser = null;
      this.helper = null;
      this.utilService.isLoad(false);
        //this.subscription.unsubscribe();
    }


    //showPassword(){
    //  this.passwordShow = ! this.passwordShow;
    //}
}
