import { NgModule } from "@angular/core";
import { HeaderComponent } from "./pages/header/header.component";
import { CommonModule } from "@angular/common";
import { SharedMaterialModule } from "./material/material.module";
import { NgxMaskModule } from "ngx-mask";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";
import { RouterModule } from "@angular/router";
import { AgGridMyModule } from "src/app/shared/grid/ag-grid.module";
import { TreeviewModule } from "ngx-treeview";
import { ToolTipComponent } from "./pages/tool-tip/tool-tip.component";

@NgModule({
  declarations: [HeaderComponent, ToolTipComponent],
  imports: [
    CommonModule,
    SharedMaterialModule,
    NgxMaskModule.forRoot(),
    TreeviewModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    AgGridMyModule,
    //ToastrModule.forRoot({
    //  timeOut: 10000,
    //  positionClass: 'toast-top-right'
    //}),
  ],
  exports: [HeaderComponent],

  entryComponents: [ToolTipComponent],
})
export class SharedModule {}
