import { NgModule } from "@angular/core";
import { LoginComponent } from "./login/login.component";
import { CoreComponent } from "./core.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { RouterModule } from "@angular/router";
import { CoreRoutingModule } from "./core.routing";
import { SharedModule } from "../shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";

import { SharedMaterialModule } from "../shared/material/material.module";
import { PocComponent } from "./poc/poc.component";
import { httpInterceptorProviders } from "../intercepter";
import { HomeComponent } from "./home/home.component";
import { MsalService } from "../services/msal.service";
import { CommonModule } from "@angular/common";
import { BlankRedirectComponent } from "./blank-redirect/blank-redirect.component";

@NgModule({
  declarations: [
    CoreComponent,
    NotFoundComponent,
    NotFoundComponent,
    LoginComponent,
    PocComponent,
    HomeComponent,
    BlankRedirectComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    CoreRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedMaterialModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      newestOnTop: false,
      autoDismiss: true,
      preventDuplicates: true,
      timeOut: 1500,
      extendedTimeOut: 0,
      positionClass: "toast-top-right",
    }),
  ],
  exports: [SharedMaterialModule],
  entryComponents: [],
  providers: [httpInterceptorProviders, MsalService],
})
export class CoreModule {}
