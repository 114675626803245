export class Validations {
    public static Message = {
      serverNotFound: 'Your server connection is lost.\n Please try again.',
      InvalidUsers: 'Your session has expired. Please login again.',
      username: 'The username field is required.',
      usernameRequired: 'The username field is required.',
      passwordRequired: 'The password field is required.',
      passwordPattern: 'The password should contains maximum of 8 length.',
        email: 'The  Email field is invalid.',
        emailFormat:'The email must be in valid format i.e username@gmail.com.',
      loan_exists: 'This loan number is already in our system for your company. Please select another loan number.',
        loanNumberRequired: 'The Loan number field is required.',
      loanNumberMinLength: 'The Loan number must be at least 10 digit.',
      loanNumberMaxLength: 'The Loan number must be max 15 digit.',
      loanNumberPattern: 'The Loan number must be valid formate. Ie. 95555.',
        forgetUserNameTitle: 'Forgot Username',
        forgetUserNameBodyTitleMessage: 'Enter your e-mail address below to obtain your Username.',
        forgetPasswordTitle: 'Forgot Password',
        forgetPasswordBodyTitleMessage: 'Enter your e-mail address below to reset your password.',
        emailFieldRequird: 'The email field is required.',
      loanAmount: 'The loan amount is required.',
      loanAmountPattern: 'The loan amount should be in a proper format.',
      propertyAddressRequired: 'The address field is required.',
      cityFieldRequired: 'The city field is required.',
      cityFormat: 'The city field must contains characters.',
      stateFieldRequired: 'Please select state.',
      zipFieldRequired: 'The zip field is required.',
      zipFormat: 'The zip must contain 4 digits i.e. 4333.',
      firstNameRequired: 'The First Name field is required.',
      lastNameRequired: 'The Last Name field is required.',
      ClosAgntInfoButton: 'The location field is required.',
      uploadDocRequired: 'This field is required.',
      uploadDocRequire: 'The wire field is required.',
      phoneFieldRequired: 'The phone field is required.',
      contactFieldRequired: 'The contact field is required.',
      userRoleRequired: 'The user role is required.',
      parentCompanyRequired: 'The parent company is required.',
      childCompanyRequired: 'The child company is required.',
      companyNameRequired: 'The company name field is required.',
      roleNameRequired: 'The rolename is required.',
      accessDenied: 'Access Denied.'


    }

}
