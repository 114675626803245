import { Injectable, OnInit } from '@angular/core';
import * as Msal from '../../assets/lib/msal';
import { environment } from '../../environments/environment';

import { LicenseManager } from 'ag-grid-enterprise';
import { SharedService } from './shared.service';
@Injectable()
export class MsalService implements OnInit {
  public AD_URL;
  public B2CTodoAccessTokenKey = "b2c.access.token";
  public accessToken;
  public tenantConfig;
  public authority;
  public clientApplication;
  public env;



  constructor(public sharedService: SharedService) {
    this.configAD();
  }

  ngOnInit() {
    this.configAD();
  }


  public configAD() {
    let self = this;

    self.sharedService.setConfigApi().subscribe(result => {
      console.log("IN shared set config subscribe", result);
      if (result) {
        self.env = result;
        localStorage.setItem("configData", JSON.stringify(result));
        self.AD_URL = result.MSAL_REDIRECT_URL;
        self.AD_URL = this.env.MSAL_REDIRECT_URL;
        LicenseManager.setLicenseKey(this.env.AG_GRID_KEY);
        //let scopeArray = result.B2C_SCOPE.split(",");
        let scopeArray = JSON.parse(result.B2C_SCOPE);
        //scopeArray[1] = scopeArray[1].trim()
        self.tenantConfig = {
          tenant: result.TENANT_KEY,
          clientID: result.CLIENT_KEY,
          signInPolicy: result.SIGNIN_POLICY,
          signUpPolicy: result.SIGNUP_POLICY,
          editProfilePolicy: result.EDIT_POLICY,
          resetPasswordPolicy: result.RESET_PWD_POLICY,
          redirectUri: self.AD_URL,
          b2cScopes: scopeArray
        };

        // Configure the authority for Azure AD B2C
        self.authority = self.env.MICROSOFT_LOGIN_URL + self.tenantConfig.tenant + "/" + self.tenantConfig.signInPolicy + "/" + self.tenantConfig.redirectUri;
        // Default Constructer Called...
        self.msalObjectCreate();
      }
    }, error => {
      console.log(error)
    });
  }
  msalObjectCreate() {
    let self = this;
    /*
    * B2C SignIn SignUp Policy Configuration
    */
    self.clientApplication = new Msal.UserAgentApplication(
      self.tenantConfig.clientID, self.authority,
      function (errorDesc: any, token: any, error: any, tokenType: any) {
      },
      {
        redirectUri: self.AD_URL,
        navigateToLoginRequestUrl: false,
        state: '?user-type',
        cacheLocation: 'localStorage'
      }
    );
  }
  public login(): void {
    this.clientApplication.authority = this.env.MICROSOFT_LOGIN_URL + this.tenantConfig.tenant + "/" + this.tenantConfig.signInPolicy + "/" + this.tenantConfig.redirectUri;
    this.authenticate();
  }

  public editProfile(): void {
    this.clientApplication.authority = this.env.MICROSOFT_LOGIN_URL + this.tenantConfig.tenant + "/" + this.tenantConfig.editProfilePolicy + "/" + this.tenantConfig.redirectUri;
    this.authenticate();
  }

  public resetPassword(): void {
    this.clientApplication.authority = this.env.MICROSOFT_LOGIN_URL + this.tenantConfig.tenant + "/" + this.tenantConfig.resetPasswordPolicy + "/" + this.tenantConfig.redirectUri;
    this.authenticate();
  }

  public authenticate(): void {
    var _this = this;
    this.clientApplication.loginRedirect(this.tenantConfig.b2cScopes);

  }

  saveAccessTokenToCache(accessToken: string): void {
    this.accessToken = accessToken;
    localStorage.setItem('token', this.accessToken);
    localStorage.setItem('currentUser', JSON.stringify(this.clientApplication.getUser()))
    sessionStorage.setItem(this.B2CTodoAccessTokenKey, accessToken);
  };

  logout(): void {

    this.clientApplication.logout();
    sessionStorage.clear();
    localStorage.clear();
  };

  isLoggedIn(): boolean {
    return this.clientApplication.getUser() != null;
  };

  getUserEmail(): string {
    return this.getUser().idToken['emails'];
  }

  getUser() {
    return this.clientApplication.getUser()
  }

  getAccessToken() {
    return this.accessToken;
  }

  getAccessData() {
    var _this = this;
    setTimeout(() => {
      _this.clientApplication.acquireTokenSilent(_this.tenantConfig.b2cScopes).then(
        function (accessToken: any) {
          _this.saveAccessTokenToCache(accessToken);
        }, function (error: any) {
          _this.clientApplication.acquireTokenPopup(_this.tenantConfig.b2cScopes).then(
            function (accessToken: any) {
              _this.saveAccessTokenToCache(accessToken);
            }, function (error: any) {
              console.log("error: ", error);
            });
        })
    }, 500);

  }
}





