import { Validators } from '@angular/forms';
import { FileValidator } from 'ngx-material-file-input';

/**
 * @Validation `Username` check this method
 */
export class UsernameValidator {
    constructor() {
        return [
            '',
            Validators.compose(
                [
              
                Validators.required

                ],
            ),
        ];
    }
}

/**
 * @Validation : PasswordValidator
 */
export class PasswordValidator {
    constructor() {
        return [
            '',
          Validators.compose([
            Validators.required
          ]
               
            )
        ];
    }
}
//// [
//Validators.pattern("[A-Za-z0-9]{1,}$"),
//  Validators.minLength(5),
//  Validators.maxLength(8),
//  Validators.required
//                ],
/**
 * @Validation : ForgotPasswordValidator
 */

export class ForgotPasswordValidator {
    constructor() {
        return [
            '',
            Validators.compose(
                [
                    Validators.pattern("[a-zA-z0-9]{1,}@{1}[A-Za-z]{2,10}[.]{1}[^(web)|^(\s)]{2,5}"),
                    Validators.minLength(5),
                    Validators.required
                ],
            )
        ];
    }
}
/**
 * @Validation : ForgotPasswordValidator
 */

export class ForgotUsernameValidator {
    constructor() {
        return [
            '',
            Validators.compose(
                [
                    Validators.pattern("[a-zA-z0-9]{1,}@{1}[A-Za-z]{2,10}[.]{1}[^(web)|^(\s)]{2,5}"),
                    Validators.minLength(5),
                    Validators.required
                ],
            )
        ];
    }
}


/**
 * @Validation `New Transaction or New Loan for all` 
 * @default : this is not required.
 */
export class NewTransactionsValidation {
    constructor(flag = false) {
        if(flag){
            return [
            '',
            Validators.compose(
                [
                   // Validators.pattern("^[0-9]*$"),
                    Validators.minLength(2),
                   // Validators.maxLength(20)
                ],
            )];
        } else{
             return [
            '',
            Validators.compose(
                [
                    //Validators.pattern("^[0-9]*$"),
                    Validators.required,
                    Validators.minLength(2),
                    //Validators.maxLength(20)
                ],
            )];
        }
        
    }
}
/**
 * @Validation `FirstName` 
 * @default : this is  required.
 */
export class FirstName {
    constructor() {
        return [
            '',
            Validators.compose(
                [
                    Validators.required
                ],
            )];
    }
}
/**
 * @Validation `Required` 
 * @default : this is  required.
 */
export class Required {
    constructor() {
        return [
            '',
            Validators.compose(
                [
                    Validators.required
                ],
            )];
    }
}

export class RequiredSelect {
  constructor() {
    return [
      null,
      Validators.compose(
        [
          Validators.required
        ],
      )];
  }
}



export class fileRequiredValid {
  
constructor(){
    return  [
        undefined,
        [Validators.required, FileValidator.maxContentSize(100000)]
      ]
}
}
/**
 *@minlength: 2

 * */
export class MinLength {
  constructor(length = 2) {
    return [
      '',
      Validators.compose(
        [
          Validators.required,
          //Validators.minLength(length)
         //Validators.pattern("^[0-9]\s*\S+(?:\s+\S+){1,}"),
        ],
      )];
  }
}


/**
 * @Validation `Required` 
 * @default : this is  required.
 */
export class NotRequired {
    constructor(str: any = '') {
        return [str];
    }
}

export class NotRequiredSelect {
  constructor(str: any = null) {
    return [str];
  }
}

export class isBoolean {
    constructor(str = false) {
        return [str];
    }
}

/**
 * @Validation `NotRequiredNumber` 
 * @default : this is  required.
 */
export class NotRequiredNumber {
    constructor(str: any = '') {
        return [str];
    }
}


/**
 * @Validation `ABA Routing Number` 
 * @default : this is not but valid pattern.
 */
export class AbaRoutingRequired {
  constructor() {
    return [
      '',
      Validators.compose(
        [
          Validators.required,
          Validators.pattern("^[0-9]{1,}$"),
          Validators.minLength(9)
          //Validators.pattern("^[1-9]\d*(\.\d+)?$"),

        ]
      )];
  }
}


export class CityRequired {
    constructor(length = 2) {
        return [
          '',
          Validators.compose(
            [
              Validators.pattern("[a-z A-Z]*$"),
              Validators.required,
              Validators.minLength(length)
            ],
          )];
      }
}

export class CityPattern {
  constructor(length = 2) {
    return [
      '',
      Validators.compose(
        [
          Validators.pattern("[a-z A-Z]*$"),
          Validators.minLength(length)
        ],
      )];
  }
}
/**
 * @Validation `NotRequiredDate` 
 * @default : this is  required.
 */
export class NotRequiredFundingDate {
    constructor(str: any = '') {
        return [str];
    }
}
    


/**
 * @method: confirm Account
 */
export class ConfirmField {
    constructor(str: any = '') {
        return [
            str,
            Validators.compose(
                [
                    Validators.required
                ],
            )];
    }
}



/**
 * @method: confirm Account
 */
export class PasswordRequired {
    constructor(str: any = '') {
        return [
            str,
            Validators.compose(
                [
                Validators.required,
                Validators.minLength(8)
                ],
            )];
    }
}

/**
 * @method: confirm Account
 */
export class PasswordRequiredWithPattern {
    constructor(str: any = '') {
        return [
            str,
            Validators.compose(
                [
                 //  Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"),
                   Validators.required,
                   Validators.minLength(8)
                ],
            )];
    }
}


/**
 * @method: confirm Account
 */
export class ImagesValid {
    constructor() {
        return [
            undefined,
            Validators.compose(
                [
                    Validators.required,
                    FileValidator.maxContentSize(104857600)
                ],
            )];
    }
}

/**
 * @Validation `AccNumberValid` 
 * @default : this is not but valid pattern.
 */
export class AccNumberValid {
    constructor(str:any='') {
        return [
            '',
            Validators.compose(
                [
                    Validators.pattern("^[0-9]{1,}$"),
                    Validators.minLength(9),
                    Validators.maxLength(15),
                ],
            )];
    }
}


/**
 * @Validation `ZipValid` 
 * @default : this is not but valid pattern.
 */
export class ZipValid {
    constructor(isRequired = false) {
        const patternStr = '^[0-9]{1,}$';
      if (isRequired) {
     
             // If this is Required then
            return [
                '',
                Validators.compose(
                    [
                        Validators.required,
                        Validators.pattern(patternStr),
                        Validators.minLength(4),
                        Validators.maxLength(5),
                    ],
                )];
        } else {
            // Not Required Then Called this block
            return [
                '',
                Validators.compose(
                    [
                        Validators.pattern(patternStr),
                        Validators.minLength(4),
                        Validators.maxLength(5),
                    ],
                )];
        }
        
    }
}


/**
 * @Validation `loanamount` 
 * @default : this is not but valid pattern.
 */
export class LoanAmountRequired {
    constructor() {
        return [
            '0.00',
            Validators.compose(
                [
                Validators.required,
                Validators.min(0.01)
                //Validators.max(9999999999.99),
                //Validators.pattern("^[0-9]\d{1,10}((\.)[0-9]{0,2}){0,1}$")
             // Validators.pattern("^[0-9]\d{0,9}(\.\d{ 1,3})?%?$")
                


               // Validators.pattern("^[1-9]\d*(\.\d+)?$")
                ]
            )];
    }
}

/**
 * @Validation `phone` 
 * @default : this is not but valid pattern.
 */
export class PhoneFieldRequired {
    constructor() {
        return [
            '',
            Validators.compose(
                [
                    Validators.required,
                  
                ]
            )];
    }
}
/**
 * @Validation `contact` 
 * @default : this is not but valid pattern.
 */
export class ContactFieldRequired {
    constructor() {
        return [
            '',
            Validators.compose(
                [
                Validators.required,
                Validators.minLength(10)
                  
                ]
            )];
    }
}

export class PhonePatternOnly {
  constructor() {
    return [
      '',
      Validators.compose(
        [
          Validators.minLength(10)

        ]
      )];
  }
}

/**
 * @Validation `userrole` 
 * @default : this is not but valid pattern.
 */
export class UserRoleRequired {
    constructor() {
        return [
            '',
            Validators.compose(
                [
                    Validators.required,
                  
                ]
            )];
    }
}
/**
 * @Validation `ClientRolename` 
 * @default : this is not but valid pattern.
 */
export class RoleNameRequired {
    constructor() {
        return [
            '',
            Validators.compose(
                [
                    Validators.required,
                  
                ]
            )];
    }
}
/**
 * @Validation `ParentCompany` 
 * @default : this is not but valid pattern.
 */
export class ParentCompanyRequired {
    constructor() {
        return [
            '',
            Validators.compose(
                [
                    Validators.required,
                  
                ]
            )];
    }
}
/**
 * @Validation `ParentCompany` 
 * @default : this is not but valid pattern.
 */
export class RequiredNumber {
  constructor(isRequired = true) {

    if (isRequired) {
      return [
        '',
        Validators.compose(
          [
            Validators.required,
            Validators.pattern("^[0-9]{1,}$"),
            //Validators.minLength(length),
          ],
        )];
    } else {
      return [
        '',
        Validators.compose(
          [
            Validators.pattern("^[0-9]{1,}$")
          ],
        )];

    }


    
  }
}
export class ChildCompanyRequired {
    constructor() {
        return [
            '',
            Validators.compose(
                [
                    Validators.required,
                  
                ]
            )];
    }
}

/**
 * @Validation `Email` check this method
 */
export class EmailValidator {
  constructor(isRequired = true) {
    let emailReg = /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;|,\s*|\s*$))*$/;
   // let emailReg = /^ [a - z0 -9._ % +-] +@[a - z0 - 9. -]+\.[a - z]{ 2, 4 } $/;

    //let emailReg = /^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@+[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$/;
        if(isRequired){
     return [
              '',
            Validators.compose(
                [
                    Validators.required,
                    Validators.email,
               // Validators.pattern("[a-zA-z0-9]{3,}@{1}[A-Za-z0-9]{3,}[.]{1,}[^(web)|^(\s)]{3,}$"),
               // Validators.pattern("^(([^<>()\[\]\\.,;:\s@]+(\.[^<>()\[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$")
                Validators.pattern("^[a-zA-z0-9._-]+@[a-zA-z0-9.-]+\\.[a-zA-z]{2,}$")

                ],
            ),
        ];
        } else{
       return [
            '',
            Validators.compose(
              [
                Validators.pattern("^[a-zA-z0-9._-]+@[a-zA-z0-9.-]+\\.[a-zA-z]{2,}$")
                    //Validators.email,
               // Validators.pattern("[a-zA-z0-9]{3,}@{1}[A-Za-z0-9]{3,}[.]{1,}[^(web)|^(\s)]{3,}$"),
                //Validators.pattern("^(([^<>()\[\]\\.,;:\s@]+(\.[^<>()\[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$"),

                ],
            ),
        ];
        }
      
    }
}



