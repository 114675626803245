// Angular Deps
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

// Internal Deps
import { NotFoundComponent } from "./core/not-found/not-found.component";
/* tslint:disable */

const appRoutes: Routes = [
  {
    path: "",
    canLoad: [],
    loadChildren: () =>
      import("./modules/consumer/consumer.module").then(
        (mod) => mod.ConsumerModule
      ),
    data: { preload: false },
  },
  {
    path: "",
    loadChildren: () =>
      import("./core/core.module").then((mod) => mod.CoreModule),
    data: { preload: false },
  },

  { path: "**", component: NotFoundComponent },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { initialNavigation: !isIframe })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
