import { Component, OnInit } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Utility } from "./async/utility";
import { SharedService } from "./services/shared.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  //@Title
  title = "FundingShield";

  constructor(
    private loader: NgxUiLoaderService,
    private utilService: Utility,
    public shareService: SharedService
  ) {
    let self = this;
    self.loader.start(); // start foreground spinner of the master loader with 'default' taskId
    // Stop the foreground loading after 5s
    setTimeout(() => {
      self.loader.stop(); // stop foreground spinner of the master loader with 'default' taskId
    }, 100);
  }

  ngOnInit() {
    let self = this;

    // TODO Remove these 2 lines when you have the loging functionality
    this.shareService.setConfigApi();
    this.shareService.setConfiguaration();

    self.utilService.loaderStatusGet().subscribe((status) => {
      if (status) {
        self.loader.start();
      } else {
        setTimeout(() => {
          //self.loader.stop(); // stop foreground spinner of the master loader with 'default' taskId
        }, 100);
      }
    });
  }
}
